import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const ChatDataContext = createContext();

export const ChatDataProvider = ({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [chatData, setChatData] = useState({
    threads: [],
    myPicks: [],
    totalPicks: 0,
    publicThreads: [],
    userThreads: [],
    leaderboardData: []
  });
  const [isFetchingChat, setIsFetchingChat] = useState(false);

  const fetchChatData = async () => {
    setIsFetchingChat(true);
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://sagewager.us.auth0.com/api/v2/`,
        scope: "read:current_user",
      });
  
      // API call for chat data
      //const threadsResponse = await axios.get("https://cpdough--sagewager-api-get-chat.modal.run", {
      const threadsResponse = await axios.get("/api/getAllChats", {
        headers: { "Authorization": `Bearer ${accessToken}` }
      });
  
      // Handle threads response
      const publicThreads = threadsResponse.data.threads.filter(thread => thread[4] !== user.email);
      const userThreads = threadsResponse.data.threads.filter(thread => thread[4] === user.email);
  
      setChatData({
        threads: threadsResponse.data.threads,
        myPicks: threadsResponse.data.picks,
        leaderboardData: threadsResponse.data.leaderboard,
        totalPicks: threadsResponse.data.picks.length,
        publicThreads: publicThreads,
        userThreads: userThreads,
      });
    } catch (error) {
      console.error("Failed to fetch chat threads:", error);
    } finally {
      setIsFetchingChat(false);
    }
  };
  
  useEffect(() => {
    if (user) {
      fetchChatData();
    }
  }, [getAccessTokenSilently, user?.email]);

  return (
    <ChatDataContext.Provider value={{ chatData, isFetchingChat, fetchChatData }}>
      {children}
    </ChatDataContext.Provider>
  );
};

export const useChatData = () => useContext(ChatDataContext);