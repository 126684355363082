const agentFunctions = [
  { display: args => `Doing Research: Looking up the ${args.type} stats for ${args.name}`, value: "get_stats"},
  { display: args => `Doing Research: Looking up advanced stats for ${Array.isArray(args.queries) ? args.queries.join(', ') : args.queries}`, value: "get_advanced_stats"},
  { display: args => `Doing Research: Grabbing current odds for ${args.league} ${args.markets}`, value: "get_odds"},
  { display: args => `Doing Research: Getting the upcoming ${args.league} schedule`, value: "get_matchups"},
  { display: args => `Doing Research: Checking the ${args.odds_type} props for this matchup`, value: "get_prop_odds"},
  { display: args => `Browsing the Web: Searching Google for ${args.search_term}`, value: "search_google"},
  { display: args => `Browsing the Web: Reading URL ${args.url}`, value: "scrape_url"},
  { display: args => `Saving your Bet: ${args.amount} on ${args.pick_outcome}`, value: "make_pick"},
  { display: args => `Analyzing your Saved Picks`, value: "analyze_picks"},
  { display: args => `Doing Research: Looking for ${args.prompt} on the web`, value: "search_web"},
];
  
export default agentFunctions;