import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, Outlet } from 'react-router-dom';
import { Auth0Provider, withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'; // Import the Auth0 hook
import HomePage from './HomePage';
import Header from "./Header";
import Chat from "./Chat";
import './App.css';
import ReactGA from "react-ga4";
import ChatDetail from './ChatDetail';
import posthog from 'posthog-js';
import PrivacyPolicy from './PrivacyPolicy';
import Article from './Article';
import PickMosaic from './PickMosaic';

import { ChatDataProvider } from './contexts/ChatDataContext';
import { SubscriptionDataProvider } from './contexts/SubscriptionDataContext';

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const ChatLayout = () => {
  return (
    <ChatDataProvider>
      <SubscriptionDataProvider>
        <Outlet />
      </SubscriptionDataProvider>
    </ChatDataProvider>
  );
};


export default function App() {

  useEffect(() => {
    ReactGA.initialize("G-KJ9ZM34XJE");
    posthog.init('phc_nDNVj1QCsLLyL65yBIvDXzl99SXsGwY4Ext2KIP3tn', { api_host: 'https://us.posthog.com' })
  }, []);

  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
      domain="sagewager.us.auth0.com"
      clientId="uNmIvJGgaNQ8pQxcu3ZlzRH6NorObDLJ"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://sagewager.us.auth0.com/api/v2/",
          scope: "openid profile email offline_access"
        }}
      useRefreshTokens
      cacheLocation="localstorage"
      >
        <HeaderWrapper />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/picks" element={<PickMosaic />} />
          <Route element={<ChatLayout />}>
            <Route path="/chat" element={<ProtectedRoute component={Chat} />} />
            <Route path="/chat/:threadId" element={<ProtectedRoute component={ChatDetail} />} />
            <Route path="/article/:threadId" element={<ProtectedRoute component={Article} />} />
          </Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

const HeaderWrapper = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return <Header isAuthenticated={isAuthenticated} loginWithRedirect={loginWithRedirect} />;
};