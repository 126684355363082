// ChatHistory.js
import React from 'react';
import { Link } from 'react-router-dom';
import leaguesData from './League';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useChatData } from './contexts/ChatDataContext';

import { BarList, Bold, Card, Flex, Text, Title, Callout } from "@tremor/react";

const currencyFormatter = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value);
};

const generateLeaderboard = (leaderboardData, league) => {
  // Filter data by league if a league is specified
  const filteredData = league
    ? leaderboardData.filter(item => item.league === league)
    : leaderboardData;

  const formattedData = filteredData.reduce((acc, item) => {
    const key = league ? `${item.user_alias}-${item.league}` : item.user_alias; // Unique key for user or user-league pair
    if (acc[key]) {
      acc[key].value += item.netprofitloss;
      acc[key].bets_won += item.bets_won;
      acc[key].bets_lost += item.bets_lost;
    } else {
      acc[key] = {
        name: item.user_alias,
        value: item.netprofitloss,
        bets_won: item.bets_won,
        bets_lost: item.bets_lost,
        leagues: league ? [item.league] : [] // Initialize leagues array for overall leaderboard
      };
    }
    // If not filtering by league, append the league to the leagues array
    if (!league) {
      acc[key].leagues.push(item.league);
    }
    return acc;
  }, {});

  // Update the name to include the accumulated bets won and lost
  Object.keys(formattedData).forEach(key => {
    const record = formattedData[key];
    record.name = `${record.name} (${record.bets_won} - ${record.bets_lost})`;
    if (!league) {
      // Remove duplicate leagues and sort them
      record.leagues = [...new Set(record.leagues)].sort();
    }
  });

  // Convert the object into an array, sort it, and limit to top 5
  return Object.values(formattedData)
    .sort((a, b) => b.value - a.value)
    .slice(0, 5); // Limit to top 5 entries
};

const ChatHistory = () => {
  const { chatData } = useChatData(); // Use the useChatData hook to access chatData
  const leaderboardData = chatData.leaderboardData; // Access leaderboardData from chatData


  const overallLeaderboard = generateLeaderboard(leaderboardData);

  if (leaderboardData.length === 0) {
    return null;
  }

  return (
    <div className="w-full max-w-3xl mx-auto px-2">
    <h2 className="text-2xl mt-2 font-bold text-center">SageWager Leaderboard</h2>
    <h3 className="text-base leading-5 text-gray-500 font-bold text-center">Last 2 Weeks</h3>
    <Callout
        className="py-4 my-4"
        title="Ask SageWager to save some picks for you to get on the Leaderboard!"
        icon={ExclamationCircleIcon}
        color="emerald"
      >
        
      </Callout>
    <Card className="w-full">
    <Title>👑 SageWager Champion Bettors</Title>
    <Flex className="mt-4">
      <Text>
        <Bold>Bettor</Bold>
      </Text>
      <Text>
        <Bold>SageBucks</Bold>
      </Text>
    </Flex>
    <BarList data={overallLeaderboard} valueFormatter={currencyFormatter} className="mt-2" />
  </Card>

  {
  leaguesData.map((league) => {
    const leagueLeaderboard = generateLeaderboard(leaderboardData, league.value);
    return leagueLeaderboard.length > 0 && (
      <Card key={league.value} className="w-full mt-4">
        <Title>{league.display} Top Bettors</Title>
        <Flex className="mt-4">
          <Text>
            <Bold>Bettor</Bold>
          </Text>
          <Text>
            <Bold>SageBucks</Bold>
          </Text>
        </Flex>
        <BarList data={leagueLeaderboard} valueFormatter={currencyFormatter} className="mt-2" />
      </Card>
    );
  })
}

  </div>
  );
};

export default ChatHistory;