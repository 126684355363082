import React, { useEffect, useState } from "react";
import { Grid, Col } from "@tremor/react";
import BetCard from "./BetCard";
import { Link } from "react-router-dom";
import leagues from "./League";
import Spinner from "./Spinner";
import Banner from "./PromoBanner";

const PickMosaic = () => {
  const [picks, setPicks] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [leaguesWithPicks, setLeaguesWithPicks] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  useEffect(() => {
    setIsLoading(true); // Set isLoading to true
    fetch("/api/getPicks")
      .then((response) => response.json())
      .then((data) => {
        // Sort the data by create_timestamp in descending order
        const sortedData = data.sort(
          (a, b) => new Date(b.create_timestamp) - new Date(a.create_timestamp)
        );

        // Filter out picks that don't have recent_trend_stat_values or recent_trend_stat_name
        const filteredData = sortedData.filter((pick) => {
          return pick.tool_call.every((call) => {
            const { name, arguments: args } = call.function;
            let parsedArgs = {};
            try {
              parsedArgs = JSON.parse(args);
            } catch (error) {
              console.error("Error parsing args:", error);
            }
            return (
              name !== "make_pick" ||
              (parsedArgs.recent_trend_stat_values &&
                parsedArgs.recent_trend_stat_values.length > 0 &&
                parsedArgs.recent_trend_stat_name &&
                parsedArgs.recent_trend_stat_name.trim() !== "")
            );
          });
        });

        setPicks(filteredData);

        // Update leaguesWithPicks
        const leaguesWithPicksSet = new Set();
        filteredData.forEach((pick) => {
          const pickLeague = JSON.parse(
            pick.tool_call[0].function.arguments
          ).league;
          leaguesWithPicksSet.add(pickLeague);
        });
        setLeaguesWithPicks(Array.from(leaguesWithPicksSet));

        setIsLoading(false); // Set isLoading to false
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false); // Set isLoading to false if there's an error
      });
  }, []);

  const filterPicksByLeague = (leagueValue) => {
    setSelectedLeague(leagueValue);
  };

  const filteredPicks = selectedLeague
    ? picks.filter((pick) => {
        const pickLeague = JSON.parse(
          pick.tool_call[0].function.arguments
        ).league;
        return pickLeague === selectedLeague;
      })
    : picks;

  return (
    <div className="mx-auto w-full px-6 lg:px-8">
      <Banner />
      <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl my-6 text-center">
        SageWager Picks
      </h1>

      <div className="flex flex-wrap justify-center gap-2 my-4">
        <button
          className={`inline-flex items-center justify-center rounded-full px-3 py-1 border border-transparent text-sm font-medium ${
            selectedLeague === null
              ? "bg-indigo-500 text-white"
              : "bg-gray-300 text-black"
          } hover:bg-indigo-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          onClick={() => setSelectedLeague(null)}
        >
          🤑 All Leagues
        </button>
        {leagues
          .filter(
            (league) =>
              !league.offseason && leaguesWithPicks.includes(league.value)
          )
          .map((league) => (
            <button
              key={league.value}
              className={`inline-flex items-center justify-center rounded-full px-3 py-1 border border-transparent text-sm font-medium ${
                selectedLeague === league.value
                  ? "bg-indigo-500 text-white"
                  : "bg-gray-300 text-black"
              } hover:bg-indigo-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              onClick={() => filterPicksByLeague(league.value)}
            >
              {league.display}
            </button>
          ))}
      </div>

      <div className="flex flex-col justify-center items-center">
        {isLoading && (
          <>
            <Spinner />
            <h2 className="mt-4 text-lg text-gray-600">
              Loading Recent SageWager Picks...
            </h2>
          </>
        )}
      </div>

      <Grid numItems={1} numItemsLg={3} className="gap-2">
        {(() => {
          const sequences = [
            [2, 1],
            [1, 2],
            [1, 1, 1],
          ];
          let pickIndex = 0;
          let cols = [];

          while (pickIndex < filteredPicks.length) {
            // Select a random sequence
            const sequence =
              sequences[Math.floor(Math.random() * sequences.length)];

            // Render the picks according to the sequence
            for (let i = 0; i < sequence.length; i++) {
              if (pickIndex >= filteredPicks.length) {
                break;
              }

              const pick = filteredPicks[pickIndex];
              cols.push(
                ...pick.tool_call.map((call, callIndex) => {
                  const { name, arguments: args } = call.function;
                  let parsedArgs = {};
                  try {
                    parsedArgs = JSON.parse(args);
                  } catch (error) {
                    console.error("Error parsing args:", error);
                  }
                  if (name === "make_pick") {
                    return (
                      <Col
                        numColSpan={1}
                        numColSpanLg={sequence[i]}
                        key={callIndex}
                      >
                        <BetCard betDetails={parsedArgs} />
                      </Col>
                    );
                  }
                  return null; // Or handle other function names if necessary
                })
              );

              pickIndex++;
            }
          }

          return cols;
        })()}
      </Grid>

      <div className="mx-auto max-w-2xl py-20 lg:py-22">
        <div className="text-center">
          <h2 className="text-2xl mt-4 font-bold text-gray-600">
            Now, use SageWager AI to make your own picks.
          </h2>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/chat"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get Started
            </a>
          </div>
        </div>
      </div>

      <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl my-6 text-center">
            Find us on <span className="line-through">Twitter</span> X
          </h2>
          <a
            class="twitter-timeline"
            data-height="700"
            href="https://twitter.com/Sage_Wager?ref_src=twsrc%5Etfw"
          >
            Tweets by Sage_Wager
          </a>
        </div>
      </section>

      <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-4xl" id="aboutus">
          <img
            className="mx-auto h-12"
            src="https://tailwindui.com/img/logos/workcation-logo-indigo-600.svg"
            alt=""
          />
          <figure className="mt-10">
            <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
              <p>
                SageWager is built by{" "}
                <span className="line-through">degenerates</span> college
                roommates who love sports and betting, based in North Carolina.
              </p>
            </blockquote>
          </figure>
        </div>
      </section>

      <footer className="flex justify-center space-x-4 p-4 z-10">
        <Link
          to="/"
          className="text-sm font-semibold leading-6 text-gray-900 z-10"
        >
          Home
        </Link>
        <Link
          to="/privacy-policy"
          className="text-sm font-semibold leading-6 text-gray-900 z-10"
        >
          Privacy Policy
        </Link>
        <a
          href="https://www.asterave.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm font-semibold leading-6 text-gray-900 z-10"
        >
          Built by Aster
        </a>
      </footer>
      <p className="text-center text-xs justify-center">
        Disclaimer: SageWager is for entertainment purposes only and does not
        involve real money betting. Gambling Problem? Call 1-800-Gambler.
      </p>
    </div>
  );
};

export default PickMosaic;
