// ChatHistory.js
import React from 'react';
import { Link } from 'react-router-dom';
import models from './Models';

const ChatHistory = ({ userThreads, user }) => {
  if (userThreads.length === 0) {
    return null;
  }

  return (
    <>
      <div className="w-5/6 lg:w-2/3 justify-center items-center mx-auto">
        <h2 className="text-2xl py-4 font-bold text-center">{user.name}'s Chat History</h2>
        {userThreads.sort((a, b) => new Date(b.create_timestamp) - new Date(a.create_timestamp)).map((thread, index) => {
          const date = new Date(thread.create_timestamp);
          const localDate = date.toLocaleString();
          const model = models.find(m => m.value === thread.model);

          return (
            <Link to={`/chat/${thread.thread_id}`} key={index}>
              <div className="flex justify-between items-center bg-gray-50 hover:bg-gray-200 shadow rounded p-4 mb-4">
                <div className="flex-grow min-w-0">
                  <p className="text-sm text-gray-600">
                    <span className="inline-block bg-gray-300 rounded-md px-2 py-1 text-sm font-semibold text-black mr-2">
                      {model ? model.display : 'Model not found'}
                    </span>
                    {localDate}
                  </p>
                  <h3 className="text-md font-semibold text-gray-900 text-left">{thread.first_message}</h3>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default ChatHistory;