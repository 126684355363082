import React from "react";
import { Link } from "react-router-dom";
import models from "./Models";

import { useSubscriptionData } from "./contexts/SubscriptionDataContext";
import { useChatData } from "./contexts/ChatDataContext";

const SubscribeManageAccount = () => {
  // Use the hooks to get data and functions from the contexts
  const { subscriptionData, isFetchingSubscription, fetchSubscriptionData, accessToken, user } = useSubscriptionData();
  const { chatData, isFetchingChat } = useChatData();
  const { threads } = chatData;

  return (
    <>
      <div className="w-5/6 lg:w-2/3 justify-center items-center mx-auto">
        <h2 className="text-2xl py-4 font-semibold text-center">
          Buy 10 pick credits for $1 or 50 for $5
        </h2>
        <div className="flex justify-center items-center">

        <stripe-buy-button
          buy-button-id="buy_btn_1OyOWlKFxdT22Qzoebr2wTNT"
          publishable-key="pk_live_51NJmx5KFxdT22QzoUTwBpAcJDVz5ATfLHqpkXyEsvm6VhO0ihXtfSIpYXaSyPsimxe8EaESS48folMuJ3l313grm00wxZe9NZw"
          customer-email={user.email}
        ></stripe-buy-button>
        </div>

      </div>
      <div className="w-5/6 lg:w-2/3 justify-center items-center mx-auto">
        <h2 className="text-2xl py-4 font-bold text-center">
          {user.name}'s Recent Chats
        </h2>
        {threads
          .sort((a, b) => new Date(b.create_timestamp) - new Date(a.create_timestamp))
          .map((thread, index) => {
            const date = new Date(thread.create_timestamp);
            const localDate = date.toLocaleString();
            const model = models.find((m) => m.value === thread.model);

            return (
              <Link to={`/chat/${thread.thread_id}`} key={index}>
                <div className="flex justify-between items-center bg-gray-50 hover:bg-gray-200 shadow rounded p-4 mb-4">
                  <div className="flex-grow min-w-0">
                    <p className="text-sm text-gray-600">
                      <span className="inline-block bg-gray-300 rounded-md px-2 py-1 text-sm font-semibold text-black mr-2">
                        {model ? model.display : "Model not found"}
                      </span>
                      {localDate}
                    </p>
                    <h3 className="text-md font-semibold text-gray-900 text-left">
                      {thread.first_message.length > 100
                        ? thread.first_message.substring(0, 250) + "..."
                        : thread.first_message}
                    </h3>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </>
  );
};

export default SubscribeManageAccount;