// Banner.js
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Banner = () => {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-green-50 bg-opacity-50 px-6 py-2.5 sm:px-3.5">
      <div className="flex flex-wrap items-center mx-auto gap-x-4 gap-y-2">
        <p className="text-sm text-center leading-6 text-gray-900">
          <strong className="font-semibold">Welcome to SageWager!</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Follow us on Twitter for updates <a href="https://twitter.com/sage_wager" target="_blank" rel="noopener noreferrer" className='underline'>@Sage_Wager</a>. Enjoy your 3 free pick credits!
        </p>
      </div>
    </div>
  );
};

export default Banner;