// PromoBanner.js
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Banner = () => {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-green-50 bg-opacity-50 px-6 py-2.5 sm:px-3.5">
      <div className="flex flex-wrap items-center mx-auto gap-x-4 gap-y-2">
        <p className="text-sm text-center leading-6 text-gray-900">
          <strong className="font-semibold">Sponsored by Dabble!</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Sign up for <a href="https://apps.apple.com/us/app/dabble-real-money-pick-em/id6467868797" target="_blank" rel="noopener noreferrer" className='underline'>Dabble real money pick'em</a> using code <strong className="font-semibold">SageWagerAI</strong> for a free $10! No deposit required. Go cash some player props 🤑
        </p>
      </div>
    </div>
  );
};

export default Banner;