// Chat.js
import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";

import ReactMarkdown from "react-markdown";
import assistants from "./Assistants";
import Banner from "./Banner";
import models from "./Models";
import agentFunctions from "./AgentFunctions.js";
import BetCard from "./BetCard";

function CollapsibleText({ children }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div>
      <button onClick={toggleCollapse} className="flex items-center">
        <ChevronDoubleDownIcon
          className={`h-5 w-5 mx-2 ${
            isCollapsed ? "transform rotate-0" : "transform rotate-180"
          }`}
          aria-hidden="true"
        />
        <span>{isCollapsed ? "See Raw Data" : "Hide Raw Data"}</span>
      </button>
      {!isCollapsed && <div>{children}</div>}
    </div>
  );
}

const ChatDetail = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [newMessage, setNewMessage] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [responseMessage, setResponseMessage] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(assistants[0]); // Set default assistant
  const [showBanner, setShowBanner] = useState(false);
  const [model, setModel] = useState(null);
  const domain = "sagewager.us.auth0.com";

  const getDisplayValue = (functionName, args) => {
    const functionObj = agentFunctions.find(
      (func) => func.value === functionName
    );
    return functionObj ? functionObj.display(args) : functionName;
  };

  const Spinner = () => (
    <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tail = queryParams.get("tail");

  let { threadId: threadIdFromParams } = useParams();
  const [threadId, setThreadId] = useState(threadIdFromParams || null);

  const messagesEndRef = React.useRef(null); // Create a ref for the messages container for scrolling

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      setAccessToken(token);
    };
    getAccessToken();
  }, [getAccessTokenSilently]);

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = (message = newMessage) => {
    setIsLoading(true);

    const createChatUrl = "https://cpdough--sagewager-api-new-chat.modal.run";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const new_conversation = {
      assistant_id: selectedAssistant.assistantId,
      message: message,
      thread_id: threadId,
      model: model.value,
    };

    axios
      .post(createChatUrl, new_conversation, { headers })
      .then((response) => {
        setNewMessage(""); // Clear the chat message
        // Increment the message counter
        setMessageCount((prevCount) => prevCount + 1);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Create Chat Failed:", error);
        window.alert(
          "Create Chat Failed: " +
            error.message +
            "\nDetails: " +
            JSON.stringify(error.response.data)
        );

        // Check if the status code is 402
        if (error.response && error.response.status === 402) {
          // Show the banner
          setShowBanner(true);
        }
      });
  };

  useEffect(() => {
    if (accessToken && threadId) {
      setIsLoading(true); // Set isLoading to true
      let interval = null;

      interval = setInterval(() => {
        axios
          .get("https://cpdough--sagewager-api-get-chat.modal.run", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              thread_id: threadId,
            },
          })
          .then((response) => {
            const status = response.data.conversation_status;
            const modelValue = response.data.messages[0].model; // Get the model from the response
            const modelDisplay = models.find(
              (model) => model.value === modelValue
            )?.display;
            setModel({ value: modelValue, display: modelDisplay });
            if (response.data.messages) {
              setResponseMessage(response.data.messages);
            }
            if (status === "completed") {
              clearInterval(interval); // Stop polling
              setIsLoading(false); // Stop loading
              messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
            } else if (["expired", "cancelled", "failed"].includes(status)) {
              clearInterval(interval); // Stop polling
              setIsLoading(false); // Stop loading
              alert(
                "Sorry, something went wrong. Please try sending your message again."
              );
            }
          })
          .catch((error) => {
            console.error("Get Chat Failed:", error);
            setIsLoading(false);
            clearInterval(interval); // Stop polling
            window.alert(
              "Sorry, something went wrong loading this chat. Please try again."
            );
          });
      }, 2500); // Poll every 2.5 seconds

      // Clean up the interval on unmount
      return () => {
        clearInterval(interval);
        setIsLoading(false); // Stop loading
      };
    }
  }, [threadId, messageCount, accessToken]);

  return (
    <div className="pt-4 pb-12 flex flex-col">
      {showBanner && <Banner />}
      {threadId && (
        <div className="px-2">
          <div className="flex items-center justify-center py-3">
            <nav
              class="flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
              aria-label="Breadcrumb"
            >
              <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li class="inline-flex items-center">
                  <Link
                    to="/chat"
                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 hover:text-white"
                  >
                    <svg
                      class="w-3 h-3 me-2.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                    </svg>
                    My SageWager
                  </Link>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <div class="flex flex-wrap-nowrap">
                      <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                        {tail === "Yes" ? "Pick to Tail" : "Your Pick"}
                      </span>
                      {isLoading && <Spinner />}
                    </div>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          <div className="overflow-y-auto">
            {responseMessage.slice(0).map((item, index) => {
              // Exclude messages with role 'function' or 'tool'
              if (item.role === "tool" || item.role === "function") return null;

              // Extract function name and arguments if they exist
              let functionDetails = "";
              let betCard = null;

              let functionCalls = [];
              if (Array.isArray(item.tool_call)) {
                functionCalls = item.tool_call.map((call) => {
                  const { name, arguments: args } = call.function; // Access the function property
                  let parsedArgs = {};
                  if (args) {
                    try {
                      parsedArgs = JSON.parse(args);
                    } catch (error) {
                      console.error("Error parsing args:", error);
                    }
                  }
                  if (name === "make_pick") {
                    return <BetCard betDetails={parsedArgs} />;
                  }
                  return getDisplayValue(name, parsedArgs);
                });
              }

              const username = item.username;

              return (
                <React.Fragment key={index}>
                  <div
                    className={`flex items-center ${
                      item.role === "user" ? "justify-end" : ""
                    }`}
                  >
                    <img
                      src={
                        item.role === "assistant"
                          ? selectedAssistant.assistantLogo
                          : tail === "Yes"
                          ? selectedAssistant.assistantLogo
                          : user.picture
                      }
                      alt={
                        item.role === "assistant"
                          ? selectedAssistant.name
                          : tail === "Yes"
                          ? "SageWager Team"
                          : user.name
                      }
                      className="w-6 h-6 object-cover rounded-full mr-2"
                    />
                    <p
                      className={`font-bold ${
                        item.role === "user" ? "text-right" : ""
                      }`}
                    >
                      {item.role === "assistant"
                        ? selectedAssistant.name
                        : tail === "Yes"
                        ? "SageWager Team"
                        : username}
                    </p>
                    <span className="inline-block bg-gray-300 rounded-md px-2 py-1 text-xs font-semibold text-black mx-2">
                      {model.display}
                    </span>
                    <p className="text-gray-500 text-sm">
                      {new Date(item.create_timestamp).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p>
                  </div>
                  <div
                    key={index}
                    className={`mt-2 p-2 rounded mb-3 ${
                      item.role === "assistant"
                        ? "text-black text-left"
                        : "bg-blue-100"
                    } max-w-3xl w-full ${
                      item.role === "assistant" ? "mr-auto mr-0" : "ml-auto"
                    }`}
                  >
                    <ReactMarkdown
                      className="prose prose-base prose-a:text-blue-600"
                      key={index}
                      components={{
                        code({ node, inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || "");
                          if (!inline && match && match[1] === "json") {
                            return (
                              <CollapsibleText>{children}</CollapsibleText>
                            );
                          }
                          return <code className={className} {...props} />;
                        },
                      }}
                    >
                      {item.first_message}
                    </ReactMarkdown>
                    {functionCalls &&
                      functionCalls.map(
                        (detail, index) =>
                          typeof detail === "string" ? (
                            <span
                              key={index}
                              className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
                            >
                              {detail}
                            </span>
                          ) : (
                            detail
                          ) // This will be the BetCard component
                      )}
                  </div>
                </React.Fragment>
              );
            })}
            {isLoading && (
              <React.Fragment>
                <div className="flex items-center">
                  <img
                    src={selectedAssistant.assistantLogo}
                    alt={selectedAssistant.name}
                    className="w-6 h-6 object-cover rounded-full mr-2"
                  />
                  <p className="font-bold">{selectedAssistant.name}</p>
                  <p className="text-gray-500 text-sm ml-2">
                    {new Date().toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
                <div className="mt-2 p-2 rounded mb-3 text-black text-left max-w-3xl mr-auto mr-0">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </React.Fragment>
            )}
            <div ref={messagesEndRef} />
          </div>
          {tail !== "Yes" && (
            <div className="flex fixed bottom-4 px-2 w-full">
              <input
                type="text"
                value={newMessage}
                onChange={handleNewMessageChange}
                className="border rounded p-2 w-full"
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleSendMessage();
                  }
                }}
                placeholder={`Ask ${
                  selectedAssistant ? selectedAssistant.name : "AI"
                } a question...`}
              />
              <button
                onClick={() => handleSendMessage()}
                disabled={isLoading}
                className={`bg-blue-500 text-white rounded px-6 py-2 mx-3 flex items-center ${
                  isLoading ? "opacity-50" : ""
                }`}
              >
                {isLoading ? (
                  <>
                    <Spinner />
                    <span>Researching...</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faArrowCircleUp} className="mr-2" />{" "}
                    Send
                  </>
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatDetail;
