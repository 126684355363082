// assistants.js
const assistants = [
    {
      "name": "SageWager AI",
      "assistantId": "asst_FqQyQ3O2QMKkxHLzAwsDsg3l",
      "assistantDescription": "AI Assistant for Betting",
      "assistantLogo": "/favicon.png"
    },
    // ... other assistants ...
  ];
  
  export default assistants;