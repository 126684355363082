// Chat.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { ArrowTrendingUpIcon, SparklesIcon, ChatBubbleLeftEllipsisIcon, CurrencyDollarIcon, BriefcaseIcon, ChartBarSquareIcon, TicketIcon } from '@heroicons/react/24/outline';

import { useChatData } from './contexts/ChatDataContext';
import { useSubscriptionData } from './contexts/SubscriptionDataContext';

import ChatHistory from './ChatHistory';
import Leaderboard from './Leaderboard';
import MakePick from './MakePick';
import MakeParlay from './MakeParlay';
import AnalyzePicks from './AnalyzePicks';
import SubscribeManageAccount from './SubscribeManageAccount';
import Banner from './Banner';

import posthog from 'posthog-js';

const ActionCard = ({ Icon, title, description, onClick, iconColor, active }) => (
  <button onClick={onClick} className={`border py-2 md:py-3 px-5 transition duration-200 ease-in-out ${active ? 'shadow-lg border-blue-500 border-2' : ''} hover:border-blue-400 hover:shadow-md`}>
    <div className="flex">
      <div className="items-start">
        <Icon className={`h-8 w-8 md:h-12 md:w-12 ${iconColor}`} />
      </div>
      <div className="mx-auto justify-center items-center text-center">
        <h2 className="font-bold text-md md:text-lg">{title}</h2>
        <p className="text-sm md:text-md text-gray-600">{description}</p>
      </div>
    </div>
  </button>
);

const DisabledCard = ({ Icon, title, description, iconColor, isFetching }) => (
  <div className="border py-2 md:py-4 px-5 bg-gray-200">
    <div className="flex">
      <div className="items-start">
        <Icon className={`h-8 w-8 md:h-12 md:w-12 ${iconColor}`} />
      </div>
      <div className="mx-auto justify-center items-center text-center">
        <h2 className="font-bold text-md md:text-lg">{title}</h2>
        <p className="text-sm md:text-md text-gray-600">{isFetching ? 'Loading...' : description}</p>
      </div>
    </div>
  </div>
);


const Chat = () => {
  const { chatData, isFetchingChat } = useChatData();
  const { subscriptionData, isFetchingSubscription, fetchSubscriptionData } = useSubscriptionData();
  const [showBanner, setShowBanner] = useState(true);
  const [activeComponent, setActiveComponent] = useState('MakeParlay');

  let { threadId: threadIdFromParams } = useParams();
  const [threadId, setThreadId] = useState(threadIdFromParams || null);

  const navigate = useNavigate();

  return (
    <div className="pb-12 flex flex-col">
      {showBanner && <Banner />}
        <div className="w-5/6 md:max-w-5xl mx-auto mt-2">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
          <ActionCard 
            Icon={TicketIcon} 
            title="Make a Pick" 
            description="Analyze the data and cook up a Winner" 
            onClick={() => setActiveComponent('MakeParlay')}
            iconColor="text-emerald-600"
            active={activeComponent === 'MakeParlay'}
          />

          <ActionCard 
            Icon={ChatBubbleLeftEllipsisIcon} 
            title="My Chat History" 
            description="View your chat history with SageWager" 
            onClick={() => setActiveComponent('SubscribeManageAccount')}
            iconColor="text-amber-700"
            active={activeComponent === 'SubscribeManageAccount'}
          />

        { chatData.leaderboardData.length > 0 ? (
          <ActionCard 
            Icon={ChartBarSquareIcon} 
            title="SageWager Leaderboard" 
            description={`See the top bettors on SageWager`} 
            onClick={() => setActiveComponent('Leaderboard')}
            iconColor="text-yellow-500"
            active={activeComponent === 'Leaderboard'}
          />
        ) : (
          <DisabledCard
            Icon={ChartBarSquareIcon}
            title="SageWager Leaderboard"
            description="Loading the leaderboard..."
            iconColor="text-gray-500"
            isFetching={isFetchingChat}
          />
        )}

          {chatData.myPicks.length > 0 ? (
            <ActionCard 
              Icon={CurrencyDollarIcon} 
              title="Analyze My Picks" 
              description={`See results of your ${chatData.myPicks.length} SageWager picks`}
              onClick={() => setActiveComponent('AnalyzePicks')}
              iconColor="text-blue-500"
              active={activeComponent === 'AnalyzePicks'}
            />
          ) : (
            <DisabledCard 
              Icon={CurrencyDollarIcon}
              title="Analyze My Results" 
              description="No saved picks yet. Ask SageWager to save a pick for you!"
              iconColor="text-blue-500"
              isFetching={isFetchingChat}
            />
          )}
          
          </div>
        </div>

        <hr class="my-4 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />


        {(() => {
        switch (activeComponent) {
          case 'MakePick':
            return <MakePick
                    />;

          case 'MakeParlay':
            return <MakeParlay 
                    />;

          case 'ViewChatHistory':
            return <ChatHistory />;
          
          case 'Leaderboard':
            return <Leaderboard
            />;

          case 'AnalyzePicks':
            return <AnalyzePicks 
            />;
          case 'SubscribeManageAccount':
            return <SubscribeManageAccount 
            />;
          default:
            return null;
        }
      })()}
            
    </div>
  );
};

export default Chat;