import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import posthog from 'posthog-js';

const SubscriptionDataContext = createContext();

export const SubscriptionDataProvider = ({ children }) => {
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const [subscriptionData, setSubscriptionData] = useState({});
  const [isFetchingSubscription, setIsFetchingSubscription] = useState(false);
    const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    if (!user) return; // Add this line to guard against undefined user


    const getAccessToken = async () => {
      try {
      const token = await getAccessTokenSilently({
        audience: `https://sagewager.us.auth0.com/api/v2/`,
        scope: "read:current_user",
      });
      setAccessToken(token);
      }
      catch (e) {
        console.error(e);
        logout();
      }
    };
    getAccessToken();
    posthog.identify(
      user.sub,
      { email: user.email, name: user.name } // optional: set additional user properties
    );
  }, [getAccessTokenSilently, user]);

  const fetchSubscriptionData = async () => {
    if (!user) return; // Guard against undefined user
    setIsFetchingSubscription(true);
    try {
      // Example API calls for subscription data
      const fetchStripeStatusPromise = axios.get("/api/getStripeStatus", {
        headers: { "Authorization": `Bearer ${accessToken}` }
      });
      const fetchUsagePromise = axios.get("/api/getUsage", {
        headers: { "Authorization": `Bearer ${accessToken}` },
        params: { username: user.email }
      });

      const [stripeStatusResponse, usageResponse] = await Promise.all([fetchStripeStatusPromise, fetchUsagePromise]);

      setSubscriptionData({
        ...stripeStatusResponse.data,
        ...usageResponse.data
      });
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    } finally {
      setIsFetchingSubscription(false);
    }
  };

  useEffect(() => {
    if (!accessToken) return;

    fetchSubscriptionData();
  }, [accessToken]);

  return (
    <SubscriptionDataContext.Provider value={{ subscriptionData, isFetchingSubscription, fetchSubscriptionData, accessToken, user }}>
      {children}
    </SubscriptionDataContext.Provider>
  );
};

export const useSubscriptionData = () => useContext(SubscriptionDataContext);