import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import posthog from 'posthog-js';

import assistants from './Assistants';
import models from './Models';
import leaguesData from './League';

// Filter out leagues with offseason: true
const leagues = leaguesData.filter(league => !league.offseason);

const MakePick = ({ accessToken, threadId, navigate, setThreadId, setIsLoading, isLoading, setShowBanner, subscription }) => {
  const [newMessage, setNewMessage] = useState("");
  const [selectedAssistant, setSelectedAssistant] = useState(assistants[0]); // Set default assistant
  const [league, setLeague] = useState(leagues[0].value);
  const [markets, setMarkets] = useState(Object.keys(leagues[0].markets));
  const [selectedMarket, setSelectedMarket] = useState(markets[0]);
  const [notes, setNotes] = useState("");
  const [model, setModel] = useState(models[0].value);
  const [notesPlaceholder, setNotesPlaceholder] = useState("What specific games / teams / players are you interested in?");

  const handleLeagueChange = (e) => {
    const selectedLeague = e.target.value;
    setLeague(selectedLeague);
  
    const leagueDetails = leagues.find(league => league.value.toLowerCase() === selectedLeague);
    if (leagueDetails) {
      const newMarkets = Object.keys(leagueDetails.markets);
      setMarkets(newMarkets);
      setSelectedMarket(newMarkets[0]); // Set the first market as the selected market
    }
  };

  const handleMarketChange = (e) => {
    const selectedMarket = e.target.value;
    setSelectedMarket(selectedMarket);
  
    const leagueDetails = leagues.find(l => l.value.toLowerCase() === league);
    if (leagueDetails) {
        const marketDetails = leagueDetails.markets[selectedMarket];
        if (marketDetails && marketDetails.placeholder) {
            setNotesPlaceholder(marketDetails.placeholder);
        } else {
            setNotesPlaceholder("What specific games / teams / players are you interested in?");
        }
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent form submission event
  
    setIsLoading(true); // Set isLoading to true
  
    // Find the selected league
    const selectedLeague = leagues.find(l => l.value === league);
  
    // Get the strategy for the selected market
    const strategy = selectedLeague ? selectedLeague.markets[selectedMarket].strategy : [];
  
    // Construct the message based on the form inputs
    const message = `Help me research my bet on ${league} ${selectedMarket}. I'm interested in: ${notes}.\nHere's the research I need you to do:\n${strategy.map((s, i) => `${i+1}. ${s}`).join('\n')}.`;

    // Send the message
    handleSendMessage(message);

    posthog.capture('make_pick', { league: league, market: selectedMarket, notes: notes, model: model });
  };

  const handleSendMessage = (message = newMessage) => {
    setIsLoading(true);

    const createChatUrl = "https://cpdough--sagewager-api-new-chat.modal.run";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    };
    const new_conversation = {
      assistant_id: selectedAssistant.assistantId,
      message: message,
      thread_id: threadId,
      model: model
    };

    axios.post(createChatUrl, new_conversation, { headers })
      .then(response => {
        if (response.data) {
          if (!threadId) {
            setThreadId(response.data.thread_id);
            navigate(`/chat/${response.data.thread_id}`);
          }
          console.log('API Response:', response.data);
        }
        setNewMessage(""); // Clear the chat message
      })
      .catch(error => {
        setIsLoading(false);
        console.error("Create Chat Failed:", error);
        window.alert("Create Chat Failed: " + error.message + "\nDetails: " + JSON.stringify(error.response.data));

        // Check if the status code is 402
        if (error.response && error.response.status === 402) {
          // Show the banner
          setShowBanner(true);
        }
      });

  };

  return (
    <div className="w-full lg:w-2/3 mx-auto px-2">
        <h2 className="text-2xl py-2 font-bold text-center">Make a New Pick</h2>
        <form onSubmit={handleFormSubmit} className="space-y-4">
          <div className="flex items-center justify-between">
            <label htmlFor="model" className="block text-sm font-medium text-gray-700">Model to use for this pick:</label>
            <div className="flex flex-wrap py-2 justify-end">
              {models.map((modelOption, index) => (
                <div className='my-4'>
                  <input 
                    type="radio" 
                    id={modelOption.value} 
                    name="model" 
                    value={modelOption.value} 
                    checked={model === modelOption.value} 
                    onChange={(e) => setModel(e.target.value)}
                    className="hidden"
                    disabled={modelOption.value === "gpt-4-1106-preview" && subscription && subscription.premium_messages_sent_this_month >= subscription.premium_message_cap}
                  />
                  <label htmlFor={modelOption.value} className={`w-full text-center text-sm py-4 px-4 cursor-pointer border rounded-l-md rounded-r-md ${model === modelOption.value ? 'font-bold border-blue-700 bg-blue-100 text-blue-700' : 'font-normal border-gray-200 text-gray-700'}`}>
                    {modelOption.value === 'gpt-4-1106-preview' ? `${modelOption.display} (${subscription.premium_messages_sent_this_month}/${subscription.premium_message_cap} Used)` :
                      modelOption.value === 'gpt-3.5-turbo-1106' ? `${modelOption.display} (${subscription.basic_messages_sent_this_month}/${subscription.basic_message_cap} Used)` :
                    modelOption.display}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <label htmlFor="league" className="block text-sm font-medium text-gray-700 mr-3">League to bet on:</label>
            <select id="league" value={league} onChange={handleLeagueChange} className="mt-1 block w-3/4 justify-end py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base">
              {leagues.map((league, index) => (
                <option key={index} value={league.value}>{league.display}</option>
              ))}
            </select>
          </div>
          <div className="flex items-center justify-between">
            <span className="block flex-shrink-0 text-sm font-medium text-gray-700">Bet type:</span>
            <div className="flex flex-wrap justify-end">
            {markets.map((market, index) => {
              const isPremiumOnly = leagues.find(l => l.value === league).markets[market].premium_only;
              const isPremiumModelSelected = model === 'gpt-4-1106-preview'; // replace with the value of your premium model
              const isDisabled = isPremiumOnly && !isPremiumModelSelected;
              return (
                <div key={index} className="mt-2 mr-2 relative">
                  <input 
                      id={`market-${index}`} 
                      type="radio" 
                      name="markets" 
                      value={market} 
                      checked={selectedMarket === market}
                      className="hidden"
                      onChange={handleMarketChange}
                      disabled={isDisabled}
                  />
                  <label htmlFor={`market-${index}`} className={`px-3 py-1 border-2 rounded-full cursor-pointer flex text-sm lg:text-base items-center ${selectedMarket === market ? 'border-blue-500 bg-blue-500 text-white' : isDisabled ? 'border-gray-200 text-gray-400 cursor-not-allowed' : 'border-gray-200 hover:border-blue-500'}`}>
                    {market}
                  </label>
                </div>
              );
            })}
            </div>
          </div>
          <div>
            <label htmlFor="notes" className="block text-sm font-medium text-gray-700">Focus research on:</label>
            <textarea id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} placeholder={notesPlaceholder} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" required></textarea>          </div>
          <button 
              type="submit" 
              disabled={isLoading} 
              className={`rounded py-2 px-10 my-4 mx-auto flex items-center ${isLoading ? 'bg-gray-500' : 'bg-blue-500'} text-white`}
            >
              {isLoading ? 
                <>
                  Starting Research... 🤑
                </>
                : 
                <>
                  <FontAwesomeIcon icon={faArrowCircleUp} className="mr-2" />  Submit
                </>
              }
            </button>
        </form>
      </div>
  );
};

export default MakePick;