import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp, faSync } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { useChatData } from "./contexts/ChatDataContext";
import { useSubscriptionData } from "./contexts/SubscriptionDataContext";

import assistants from "./Assistants";
import models from "./Models";
import leagues from "./League";

const AnalyzePicks = () => {
  const { subscriptionData, isFetchingSubscription, fetchSubscriptionData, accessToken, user } = useSubscriptionData();
  const { chatData, isFetchingChat, fetchChatData } = useChatData();
  const [isLoading, setIsLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [selectedAssistant, setSelectedAssistant] = useState(assistants[0]); // Set default assistant
  const [notes, setNotes] = useState("");
  const [model, setModel] = useState(models[2].value); // Set default model to gp-4o
  const [showBanner, setShowBanner] = useState(true);

  let { threadId: threadIdFromParams } = useParams();
  const [threadId, setThreadId] = useState(threadIdFromParams || null);

  const navigate = useNavigate();

  const { myPicks } = chatData; // Destructure myPicks from chatData

  // Create a mapping of league values to their display names
  const leagueDisplayNames = leagues.reduce((acc, league) => {
    acc[league.value] = league.display;
    return acc;
  }, {});

  const calculateProfit = (pick) => {
    return pick.pick_profit_loss > 0 ? pick.pick_profit_loss - pick.amount : pick.pick_profit_loss;
  };

  let displayStats = [];
  if (myPicks.length > 0) {
    const stats = myPicks.reduce((acc, pick) => {
      const leagueStats = acc[pick.league] || { profit: 0, won: 0, lost: 0 };

      return {
        ...acc,
        [pick.league]: {
          ...leagueStats,
          won: leagueStats.won + (pick.pick_profit_loss > 0 ? 1 : 0),
          lost: leagueStats.lost + (pick.pick_profit_loss < 0 ? 1 : 0),
          profit: leagueStats.profit + calculateProfit(pick),
        },
      };
    }, {});

    const statsArray = Object.entries(stats).map(([league, data]) => ({
      league,
      ...data,
    }));

    displayStats = statsArray.map(({ league, won = 0, lost = 0, profit }) => ({
      id: league,
      name: leagueDisplayNames[league],
      value: `$${profit} (${won || 0}-${lost || 0})`,
    }));
  }

  const getBackgroundColor = (pick) => {
    if (pick.pick_profit_loss > 0) {
      return "bg-green-50"; // green for won
    } else if (pick.pick_profit_loss < 0) {
      return "bg-red-50"; // red for lost
    } else {
      return "bg-gray-50"; // grey for push or pending
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent form submission event

    setIsLoading(true); // Set isLoading to true

    // Construct the message based on the form inputs
    const message = `I want to analyze my picks: ${notes}`;

    // Send the message
    handleSendMessage(message);
  };

  const handleSendMessage = (message = newMessage) => {
    setIsLoading(true);

    const createChatUrl = "https://cpdough--sagewager-api-new-chat.modal.run";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const new_conversation = {
      assistant_id: selectedAssistant.assistantId,
      message: message,
      thread_id: threadId,
      model: model,
    };

    axios
      .post(createChatUrl, new_conversation, { headers })
      .then((response) => {
        if (response.data) {
          if (!threadId) {
            setThreadId(response.data.thread_id);
            navigate(`/chat/${response.data.thread_id}`);
          }
          console.log("API Response:", response.data);
        }
        setNewMessage(""); // Clear the chat message
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Create Chat Failed:", error);
        window.alert("Create Chat Failed: " + error.message + "\nDetails: " + JSON.stringify(error.response.data));

        // Check if the status code is 402
        if (error.response && error.response.status === 402) {
          // Show the banner
          setShowBanner(true);
        }
      });
  };

  const handleRefreshPicks = () => {
    fetchChatData();
  };

  return (
    <div className="w-full lg:w-2/3 mx-auto px-2 pt-3">
      <div className="py-2 mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-2xl font-bold text-center">My Results by League</h2>
        <h3 className="text-base leading-5 text-gray-500 font-bold text-center">Last 6 Weeks</h3>
        <dl className="grid grid-cols-2 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          {displayStats.map((stat) => (
            <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
              <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl">{stat.value}</dd>
            </div>
          ))}
        </dl>
      </div>

      <h2 className="text-2xl py-2 font-bold text-center">Analyze Results with AI</h2>
      <form onSubmit={handleFormSubmit} className="space-y-4">
        <div>
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
            I want to analyze:
          </label>
          <textarea
            id="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Ask about your pick history, i.e. What percent of bets did I win last week?"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          ></textarea>
        </div>
        <button type="submit" disabled={isLoading} className={`rounded py-2 px-10 my-4 mx-auto flex items-center ${isLoading ? "bg-gray-500" : "bg-blue-500"} text-white`}>
          {isLoading ? (
            <>Starting Research... 🤑</>
          ) : (
            <>
              <FontAwesomeIcon icon={faArrowCircleUp} className="mr-2" /> Submit
            </>
          )}
        </button>
      </form>

      <div>
        <div className="flex items-center justify-center mt-8 mb-2">
          <h2 className="text-2xl font-bold text-center">My SageWager Picks</h2>
          <button onClick={handleRefreshPicks} disabled={isFetchingChat} className={`ml-2 p-2 rounded ${isFetchingChat ? "bg-gray-300" : "bg-blue-500 hover:bg-blue-600"} text-white flex items-center justify-center`}>
            <FontAwesomeIcon icon={faSync} spin={isFetchingChat} />
          </button>
        </div>
        <h3 className="text-base leading-5 mb-2 text-gray-500 font-bold text-center">Last 6 Weeks</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {myPicks.map((pick, index) => (
            <Link to={`/chat/${pick.thread_id}`} key={index}>
              <div className={`${getBackgroundColor(pick)} shadow rounded p-4 hover:bg-gray-200 transition duration-200`}>
              <p className="text-sm text-gray-600">
                  <span className="inline-block bg-gray-300 rounded-md px-2 py-1 text-sm font-semibold text-black mr-2">{leagueDisplayNames[pick.league]}</span>
                  {new Date(pick.created_at).toLocaleString()}
                </p>
                <h3 className="text-md font-semibold text-gray-900 text-left">
                  {pick.home_team} vs {pick.away_team}
                </h3>
                <p className="text-sm text-gray-600">{pick.pick_description}</p>
                <p className="text-sm text-gray-600">
                  Bet: {pick.pick_outcome} {pick.market === "spreads" && pick.line > 0 ? "+" : ""}
                  {pick.line} {pick.pick_status} | Odds: {pick.decimal_odds}
                </p>
                <p className="text-sm text-gray-600">
                  Wagered: ${pick.amount} to Pay: ${pick.pick_profit_loss}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnalyzePicks;
