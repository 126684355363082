// Chat.js
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import ReactMarkdown from "react-markdown";
import { Grid, Col } from "@tremor/react";

import assistants from "./Assistants";
import Banner from "./Banner";
import models from "./Models";
import agentFunctions from "./AgentFunctions.js";
import BetCard from "./BetCard.js";

const Article = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [messageCount, setMessageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [responseMessage, setResponseMessage] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(assistants[0]); // Set default assistant
  const [showBanner, setShowBanner] = useState(false);
  const [model, setModel] = useState(null);
  const [betDetails, setBetDetails] = useState([]);
  const domain = "sagewager.us.auth0.com";

  const Spinner = () => (
    <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  let { threadId: threadIdFromParams } = useParams();
  const [threadId, setThreadId] = useState(threadIdFromParams || null);

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      setAccessToken(token);
    };
    getAccessToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (accessToken && threadId) {
      setIsLoading(true); // Set isLoading to true
      let interval = null;

      interval = setInterval(() => {
        axios
          .get("https://cpdough--sagewager-api-get-chat.modal.run", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              thread_id: threadId,
            },
          })
          .then((response) => {
            const status = response.data.conversation_status;
            const modelValue = response.data.messages[0].model; // Get the model from the response
            const modelDisplay = models.find(
              (model) => model.value === modelValue
            )?.display;
            setModel({ value: modelValue, display: modelDisplay });
            if (response.data.messages) {
              setResponseMessage(response.data.messages.slice(-1));

              // Extract bet details from tool calls
              const newBetDetails = response.data.messages
                .flatMap((item) =>
                  Array.isArray(item.tool_call) ? item.tool_call : []
                )
                .filter((call) => call.function.name === "make_pick")
                .map((call) => JSON.parse(call.function.arguments));

              setBetDetails(newBetDetails);
            }
            if (status === "completed") {
              clearInterval(interval); // Stop polling
              setIsLoading(false); // Stop loading
            } else if (["expired", "cancelled", "failed"].includes(status)) {
              clearInterval(interval); // Stop polling
              setIsLoading(false); // Stop loading
              alert(
                "Sorry, something went wrong. Please try sending your message again."
              );
            }
          })
          .catch((error) => {
            console.error("Get Chat Failed:", error);
            setIsLoading(false);
            clearInterval(interval); // Stop polling
            window.alert(
              "Sorry, something went wrong loading this chat. Please try again."
            );
          });
      }, 4000); // Poll every 4 seconds

      // Clean up the interval on unmount
      return () => {
        clearInterval(interval);
        setIsLoading(false); // Stop loading
      };
    }
  }, [threadId, accessToken]);

  return (
    <div className="pt-4 pb-12 flex flex-col w-full">
      {showBanner && <Banner />}
      {threadId && (
        <div className="px-2">
          <div className="flex items-center justify-center py-3">
            <nav
              class="flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
              aria-label="Breadcrumb"
            >
              <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li class="inline-flex items-center">
                  <a
                    href="/chat"
                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                  >
                    <svg
                      class="w-3 h-3 me-2.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                    </svg>
                    Home
                  </a>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <div class="flex flex-wrap-nowrap">
                      <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                        SageWager Article
                      </span>
                      {isLoading && <Spinner />}
                    </div>
                  </div>
                </li>
              </ol>
            </nav>
          </div>

          <div className="overflow-y-auto">
            <h1 className="text-2xl font-bold text-gray-700 mb-2 text-center">
              SageWager Picks of the Day
            </h1>
            {responseMessage.slice(0).map((item, index) => {
              // Exclude messages with role 'function' or 'tool'
              if (item.role === "tool" || item.role === "function") return null;
              const username = item.username;

              return (
                <div className="flex flex-col w-full items-center justify-center">
                  <React.Fragment key={index}>
                    <p className="text-gray-500 text-lg text-center mb-4">
                      {new Date(item.create_timestamp).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </p>

                    <Grid numItems={1} numItemsMd={2} className="gap-2">
                      {betDetails.map((bet, index) => (
                        <Col key={index} numColSpan={1}>
                          <BetCard betDetails={bet} />
                        </Col>
                      ))}
                    </Grid>
                    <div
                      key={index}
                      className={`m-2 p-2 rounded mx-auto text-black text-left`}
                    >
                      <ReactMarkdown
                        className="prose prose-base prose-a:text-blue-600"
                        key={index}
                      >
                        {item.first_message}
                      </ReactMarkdown>
                    </div>
                  </React.Fragment>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Article;
