import React, { useState, Fragment } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import posthog from "posthog-js";
import { formatDistanceToNow } from "date-fns";
import { Card, ProgressCircle } from "@tremor/react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { useSubscriptionData } from "./contexts/SubscriptionDataContext";
import { useChatData } from "./contexts/ChatDataContext";

import assistants from "./Assistants";
import models from "./Models";
import leaguesData from "./League";

function getRelativeDate(date) {
  return formatDistanceToNow(date, { addSuffix: true });
}

const riskLevels = {
  1: {
    label: "🍀 Basically a Coin Flip",
    description: "Low risk level, around even odds",
  },
  2: {
    label: "🍀🍀 Medium",
    description: "Medium risk level, around 3/1",
  },
  3: {
    label: "🍀🍀🍀 High",
    description: "High risk level, around 5/1",
  },
  4: {
    label: "🍀🍀🍀🍀 Lottery Ticket",
    description: "Very high risk level, around 10/1",
  },
};

// Filter out leagues with offseason: true
const leagues = leaguesData.filter((league) => {
  if (league.offseason) {
    return false;
  }

  if (!league.marketKeys || typeof league.marketKeys !== "object") {
    return false;
  }

  const meaningfulKeys = Object.keys(league.marketKeys).filter(
    (key) => league.marketKeys[key].value
  );
  return meaningfulKeys.length > 0;
});

const MakeParlay = ({}) => {
  const {
    subscriptionData,
    isFetchingSubscription,
    fetchSubscriptionData,
    accessToken,
    user,
  } = useSubscriptionData();
  const { chatData, isFetchingChat, fetchChatData } = useChatData();
  const [isLoading, setIsLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [selectedAssistant, setSelectedAssistant] = useState(assistants[0]); // Set default assistant
  const [league, setLeague] = useState("");
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [model, setModel] = useState(models[2].value); // Set default model to gpt-4o
  const [games, setGames] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(1); // Set default risk level
  const [numberOfLegs, setNumberOfLegs] = useState(3); // Set default number of legs
  const [selectedResearchStrategy, setSelectedResearchStrategy] = useState("");
  const isPremiumModelSelected = model === "gpt-4o"; // replace with the value of your premium model
  const progressValue =
    (subscriptionData.sageCreditsUsed / subscriptionData.sageCredits) * 100;
  const [pickRuns, setPickRuns] = useState(1); // how many times to run the pick

  let { threadId: threadIdFromParams } = useParams();
  const [threadId, setThreadId] = useState(threadIdFromParams || null);

  const navigate = useNavigate();

  const handleLeagueChange = async (e) => {
    const selectedLeague = e.target.value;
    setLeague(selectedLeague);

    // Reset selected games when league changes
    setSelectedGames([]);

    const leagueDetails = leagues.find(
      (league) => league.value.toLowerCase() === selectedLeague
    );
    if (leagueDetails) {
      const newMarketKeys = Object.keys(leagueDetails.marketKeys);
      setMarkets(newMarketKeys);

      // Call the getEvents API
      try {
        const response = await axios.get(`/api/getEvents`, {
          params: {
            sportKey: leagueDetails.sportKey,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const futureGames = response.data.filter(
          (game) => new Date(game.commence_time) > new Date()
        );

        setGames(futureGames);

        if (futureGames.length > 0) {
          const firstGame = futureGames[0];
          setSelectedGames([firstGame.id]); // Set as an array containing the first game's ID
        }
      } catch (error) {
        console.error("Failed to fetch odds:", error);
      }
    }
  };

  const handleMarketChange = (e) => {
    const selectedOption = e.target.value;

    if (selectedMarket.includes(selectedOption)) {
      setSelectedMarket(selectedMarket.filter((m) => m !== selectedOption));
    } else {
      setSelectedMarket([...selectedMarket, selectedOption]);
    }
  };

  const handleGameSelect = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );
    setSelectedGames(selectedOptions);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const message = await prepareMessage();
      for (let i = 0; i < pickRuns; i++) {
        await handleSendMessage(message);
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      window.alert("Error in form submission: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const prepareMessage = async () => {
    const selectedLeague = leagues.find((l) => l.value === league);
    const selectedMarketKeys = selectedMarket.map(
      (market) => selectedLeague.marketKeys[market].value
    );
    const marketsString = selectedMarketKeys.join(",");

    let message = `Conduct your research on these possible bets. After you have exhausted all research angles, summarize your findings and make picks. I need a ${numberOfLegs}${
      numberOfLegs > 1 ? "-leg parlay" : "-leg bet"
    } on ${selectedMarket
      .map((market) => selectedLeague.marketKeys[market].display)
      .join(", ")}. Here's the odds:\n\n`;

    for (const gameId of selectedGames) {
      const selectedGame = games.find((game) => game.id === gameId);
      try {
        const oddsResponse = await axios.get(`/api/getOdds`, {
          params: {
            sportKey: selectedLeague.sportKey,
            eventId: gameId,
            markets: marketsString,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        message +=
          `${selectedGame.away_team} @ ${selectedGame.home_team}\n` +
          "```json\n" +
          JSON.stringify(oddsResponse.data, null, 2) +
          "\n```\n\n";
      } catch (error) {
        throw new Error("Failed to fetch odds for game " + gameId + ": " + error.message);
      }
    }

    if (selectedResearchStrategy) {
      message +=
        "Include this in your research: " +
        selectedResearchStrategy.prompt +
        ". \n";
    }

    message += "Analyze and make your picks!";
    return message;
  };

  const handleSendMessage = async (message) => {
    const createChatUrl = "https://cpdough--sagewager-api-new-chat.modal.run";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const new_conversation = {
      assistant_id: selectedAssistant.assistantId,
      message: message,
      thread_id: threadId,
      model: model,
    };

    try {
      const response = await axios.post(createChatUrl, new_conversation, { headers });
      if (response.data) {
        if (!threadId) {
          setThreadId(response.data.thread_id);
          navigate(`/chat/${response.data.thread_id}`);
        }
        console.log("API Response:", response.data);
      }
      setNewMessage("");

      // Trigger refetch in the background
      fetchSubscriptionData().catch((error) => {
        console.error("Failed to refetch subscription data:", error);
      });
      fetchChatData().catch((error) => {
        console.error("Failed to refetch chat data:", error);
      });
    } catch (error) {
      console.error("Create Chat Failed:", error);
      throw new Error("Create Chat Failed: " + error.message);
    }
  };

  return (
    <div className="w-full lg:w-2/3 mx-auto px-2">
      <h2 className="text-2xl py-2 font-bold text-center">
        Cook Up a Winner 👨🏻‍🍳
      </h2>
      {/* <p className="text-center text-gray-600">
        Need some inspiration? Check out the{" "}
        <a href="/picks" className="text-blue-500 hover:underline">
          recent picks board
        </a>
      </p> */}

      <div className="flex items-center justify-between space-y-2 my-4">
        <p className="text-sm font-medium text-gray-700">Your Pick Credits:</p>
        <Card className="max-w-xs">
          <div className="flex justify-start space-x-5 items-center">
            <ProgressCircle value={progressValue} strokeWidth={10} size="md" />
            <div>
              <p className="text-tremor-default text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">
                {subscriptionData.sageCreditsUsed}/
                {subscriptionData.sageCredits} ({Math.round(progressValue)}%)
              </p>
              <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Pick Credits Used
              </p>
              <a
                href={`https://buy.stripe.com/9AQcPVgCt1rdbT27sC?prefilled_email=${user.email}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 text-sm hover:underline"
              >
                Buy More (10 for $1)
              </a>
            </div>
          </div>
        </Card>
      </div>

      <form onSubmit={handleFormSubmit} className="space-y-4">
        <div className="flex items-center justify-between">
          <label
            htmlFor="league"
            className="block text-sm font-medium text-gray-700 mr-3"
          >
            Which League do you want to bet on?
          </label>
          <select
            id="league"
            value={league}
            onChange={handleLeagueChange}
            className="mt-1 block w-3/4 justify-end py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base"
            disabled={
              subscriptionData.sageCreditsUsed >= subscriptionData.sageCredits
            } // Disable if no credits left
          >
            <option value="" disabled>
              {subscriptionData.sageCreditsUsed >= subscriptionData.sageCredits
                ? "Buy More Credits to Make Picks"
                : "Select a league"}
            </option>
            {leagues.map((league, index) => (
              <option key={index} value={league.value}>
                {league.display}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center justify-between">
          <label
            htmlFor="games"
            className="block text-sm font-medium text-gray-700 mr-3"
          >
            Pick the Game(s):
          </label>
          <div className="relative w-3/4">
            <Listbox
              value={selectedGames}
              onChange={setSelectedGames}
              multiple
              as="div"
              disabled={!league}
              name="selectedGames"
            >
              {({ open }) => (
                <>
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 text-base overflow-hidden">
                    <span className="block truncate w-full">
                      {league
                        ? selectedGames.length > 0
                          ? selectedGames.length > 1
                            ? `${selectedGames.length} games selected`
                            : selectedGames
                                .map((gameId) => {
                                  const game = games.find(
                                    (g) => g.id === gameId
                                  );
                                  return `${game.away_team} @ ${game.home_team}`;
                                })
                                .join(", ")
                          : "Select game(s)"
                        : ""}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {games.map((game, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            `cursor-default select-none relative py-2 pl-10 pr-4 ${
                              active
                                ? "bg-blue-500 text-white"
                                : "text-gray-900"
                            }`
                          }
                          value={game.id}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                } whitespace-normal`}
                              >
                                {selected && (
                                  <CheckIcon
                                    className={`inline-block w-5 h-5 mr-2 ${
                                      active ? "text-white" : "text-blue-500"
                                    }`}
                                    aria-hidden="true"
                                  />
                                )}
                                {game.away_team} @ {game.home_team} (
                                {getRelativeDate(new Date(game.commence_time))})
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </>
              )}
            </Listbox>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <label
            htmlFor="numberOfLegs"
            className="block text-sm font-medium text-gray-700"
          >
            How many bets in this parlay?
          </label>
          <div className="py-2 px-3 inline-block bg-white border border-gray-200 rounded-lg">
            <div className="flex items-center gap-x-1.5">
              <button
                type="button"
                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                onClick={() => setNumberOfLegs(Math.max(1, numberOfLegs - 1))}
              >
                -
              </button>
              <input
                id="numberOfLegs"
                type="text"
                value={numberOfLegs}
                onChange={(e) =>
                  setNumberOfLegs(
                    Math.min(10, Math.max(1, Number(e.target.value)))
                  )
                }
                className="p-0 w-6 bg-transparent border-0 text-gray-800 text-center focus:ring-0"
              />
              <button
                type="button"
                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                onClick={() => setNumberOfLegs(Math.min(10, numberOfLegs + 1))}
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="block flex-shrink-0 text-sm font-medium text-gray-700">
            Bets to consider:
          </span>
          <div className="flex flex-wrap justify-end">
            {markets.map((market, index) => {
              const selectedLeague = leagues.find((l) => l.value === league);
              const marketDetails = selectedLeague
                ? selectedLeague.marketKeys[market]
                : null;
              const isPremiumOnly = marketDetails
                ? marketDetails.premium_only
                : false;
              const isPremiumModelSelected = model === "gpt-4o"; // replace with the value of your premium model
              const isDisabled = isPremiumOnly && !isPremiumModelSelected;
              return (
                <div key={index} className="mt-2 mr-2 relative">
                  <input
                    id={`market-${index}`}
                    type="checkbox"
                    name="markets"
                    value={market}
                    checked={selectedMarket.includes(market)}
                    className="hidden"
                    onChange={handleMarketChange}
                    disabled={isDisabled}
                  />
                  <label
                    htmlFor={`market-${index}`}
                    className={`px-3 py-1 border-2 rounded-full cursor-pointer flex text-sm lg:text-base items-center ${
                      selectedMarket.includes(market)
                        ? "border-blue-500 bg-blue-500 text-white"
                        : isDisabled
                        ? "border-gray-200 text-gray-400 cursor-not-allowed"
                        : "border-gray-200 hover:border-blue-500"
                    }`}
                  >
                    {marketDetails ? marketDetails.display : market}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="block flex-shrink-0 mr-3 text-sm font-medium text-gray-700">
            Advanced Research:
          </span>
          <select
            id="advancedResearch"
            value={selectedResearchStrategy ? selectedResearchStrategy.key : ""}
            onChange={(e) => {
              const selectedStrategy = leagues.find((l) => l.value === league)
                .researchStrategies[e.target.value];
              setSelectedResearchStrategy({
                ...selectedStrategy,
                key: e.target.value,
              });
            }}
            disabled={!isPremiumModelSelected}
            className={`mt-1 block w-3/4 justify-end py-2 px-3 border ${
              isPremiumModelSelected ? "border-gray-300" : "border-gray-200"
            } bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base`}
          >
            <option value="" disabled>
              Add an advanced analytics strategy (optional)
            </option>
            {league &&
              leagues.find((l) => l.value === league).researchStrategies &&
              Object.entries(
                leagues.find((l) => l.value === league).researchStrategies
              ).map(([key, strategy]) => (
                <option key={key} value={key}>
                  {strategy.display}
                </option>
              ))}
          </select>
        </div>

        <div className="flex items-center justify-between">
        <label
          htmlFor="pickRuns"
          className="block text-sm font-medium text-gray-700"
        >
          How many times do you want to run this pick?
        </label>
        <div className="py-2 px-3 inline-block bg-white border border-gray-200 rounded-lg">
          <div className="flex items-center gap-x-1.5">
            <button
              type="button"
              className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
              onClick={() => setPickRuns(Math.max(1, pickRuns - 1))}
            >
              -
            </button>
            <input
              id="pickRuns"
              type="text"
              value={pickRuns}
              onChange={(e) =>
                setPickRuns(Math.min(5, Math.max(1, Number(e.target.value))))
              }
              className="p-0 w-6 bg-transparent border-0 text-gray-800 text-center focus:ring-0"
            />
            <button
              type="button"
              className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
              onClick={() => setPickRuns(Math.min(5, pickRuns + 1))}
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <button
          type="submit"
          disabled={
            isLoading || !selectedGames || selectedMarket.length === 0
          }
          className={`rounded py-2 px-10 mt-10 mb-10 mx-auto flex items-center ${
            isLoading || !selectedGames || selectedMarket.length === 0
              ? "bg-gray-500"
              : "bg-blue-500"
          } text-white`}
        >
          {isLoading ? (
            <>Starting Research... 🤑</>
          ) : (
            <>
              <FontAwesomeIcon icon={faArrowCircleUp} className="mr-2" /> Make
              Picks (Uses {pickRuns} {pickRuns === 1 ? "Credit" : "Credits"})
            </>
          )}
        </button>
      </div>
      </form>
    </div>
  );
};

export default MakeParlay;
