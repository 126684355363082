import React from 'react';
import { ChatBubbleBottomCenterTextIcon, CpuChipIcon, TableCellsIcon, PresentationChartBarIcon } from '@heroicons/react/20/solid';
import Banner from './HomePageBanner';
import { Link } from 'react-router-dom';



const includedFeatures = [
  'Use SageMaker AI to research and recommend bets',
  'Live and up-to-date odds',
  'AI Research on your bets',
]


const features = [
  {
    name: 'Tell SageWager what you want to bet on',
    description:
      'SageWager can access live odds on NFL, NBA, NHL, MLB, Premier League, and more.',
    icon: ChatBubbleBottomCenterTextIcon,
  },
  {
    name: 'SageWager does research on odds, stats, and more',
    description:
      'SageWager crunches the current odds, player and team stats, and more to recommend a pick.',
    icon: CpuChipIcon,
  },
  {
    name: 'Once you know your pick, tell SageWager to save it',
    description: 'SageWager saves and grades your picks to help you track performance.',
    icon: TableCellsIcon,
  },
  {
    name: 'Analyze your results just by asking',
    description: 'Ask SageWager anything about your picks, like What is my record on underdogs this month?',
    icon: PresentationChartBarIcon,
  }
]

const HomePage = () => {

  return (
    <>
    <div className="bg-white">
      <div className="relative isolate p-3">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#004d00] to-[#008000] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <Banner />
        <div className="mx-auto max-w-2xl py-20 lg:py-22">
          <div className="text-center">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            SageWager: Your AI Betting Assistant
            </h1>
            <h2 className="text-2xl mt-4 font-bold text-gray-600">
              Use AI-powered analytics to build a winning parlay.
              <br />
              Powered by OpenAI.
            </h2>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/chat"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Make Picks with SageWager AI
              </a>
              <a href="/picks" className="text-sm font-semibold leading-6 text-gray-900">
                See Example Picks <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#004d00] to-[#008000] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="overflow-hidden py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">

          <div className="lg:pr-8 lg:pt-4" id="how-it-works">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">How it Works</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">SageWager AI does the research so you don't have to</p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          <video
            src="/SageWagerFTP.mp4"
            autoPlay
            loop
            muted
            playsInline
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>


    <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
      
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl my-6 text-center">Find us on <span className='line-through'>Twitter</span> X</h2>
        <a class="twitter-timeline" data-height="700" href="https://twitter.com/Sage_Wager?ref_src=twsrc%5Etfw">Tweets by Sage_Wager</a>
        
      </div>
    </section>


    <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
      
      <div className="mx-auto max-w-2xl lg:max-w-4xl" id="aboutus">
        <img className="mx-auto h-12" src="https://tailwindui.com/img/logos/workcation-logo-indigo-600.svg" alt="" />
        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p>
            SageWager is built by <span className='line-through'>degenerates</span> college roommates who love sports and betting, based in North Carolina.
            </p>
          </blockquote>
        </figure>
      </div>
    </section>

      </div>
    </div>

    <footer className="flex justify-center space-x-4 p-4 z-10">
    <Link to="/" className="text-sm font-semibold leading-6 text-gray-900 z-10">Home</Link>
    <Link to="/privacy-policy" className="text-sm font-semibold leading-6 text-gray-900 z-10">Privacy Policy</Link>
    <a href="https://www.asterave.com" target="_blank" rel="noopener noreferrer" className="text-sm font-semibold leading-6 text-gray-900 z-10">Built by Aster</a>
    </footer>
    <p className="text-center text-xs justify-center">Disclaimer: SageWager is for entertainment purposes only and does not involve real money betting. Gambling Problem? Call 1-800-Gambler.</p>

  
  </>
  );
};

export default HomePage;
