import React from 'react';
import leagues from './League';

import { Card, AreaChart, SparkAreaChart, SparkBarChart, SparkLineChart, Text, Title } from "@tremor/react";

const BetCard = ({ betDetails }) => {
  const { league, event_id, market, pick_outcome, pick_description, recent_trend_stat_name, stat_target_value, recent_trend_stat_values, pick_justification, amount, home_team, away_team, created_at, line, pick_status, decimal_odds, pick_profit_loss } = betDetails;

  // Handle old bets that don't have a recent_trend_stat_name
  if (!recent_trend_stat_values || !recent_trend_stat_name) {
    return "✅ Saved Bet";
  }

  // Find the league object that matches the league value
  const leagueObj = leagues.find(l => l.value === league);


// Prepare data for the AreaChart
const chartData = recent_trend_stat_values.slice().reverse().map((value, index) => {
  let label;
  if (index === 0) {
    label = "Oldest";
  } else if (index === recent_trend_stat_values.length - 1) {
    label = "Most Recent";
  } else {
    label = index.toString();
  }

  let data = {
      index: label,
      [recent_trend_stat_name]: value
    };

    if (stat_target_value !== undefined) {
      data["Line"] = stat_target_value;
    }

    return data;
});

  return (
    <Card className="w-full mx-auto shadow rounded p-4" decoration="top" decorationColor="indigo">
      <Title className="text-lg font-semibold text-gray-600">
        <span className="inline-block bg-gray-300 rounded-md px-2 py-1 text-sm lg:text-md font-semibold text-black mr-2">
          {leagueObj ? leagueObj.display : league}
        </span>
        Pick: {pick_description}
      </Title>
      <Text className="text-center mt-4 font-bold text-md">Recent {recent_trend_stat_name}</Text>
      <AreaChart
        className="h-56 mt-4"
        data={chartData}
        index="index"
        yAxisWidth={50}
        categories={[recent_trend_stat_name, "Line"]}
        colors={["indigo", "cyan"]}
        curveType="monotone"
        startEndOnly={true}
        showXAxis={true}
        showLegend={false}
      />
      <Text className="text-sm text-gray-600">
        {pick_justification}
      </Text>
    </Card>
  );
};

export default BetCard;